<template lang="pug">
.modal
  .modal__bg(@click="$emit('close')")
  .modal__content(:class="{'modal__content--full-width': fullWidth}")
    header.modal__header.modal__container
      h3.modal__ttl
        slot(name="title")
      img.modal__close(src="@/assets/images/ic-close-modal.svg" @click="$emit('close')")
    main.modal__main.modal__container
      slot
    footer
      template(v-if="$slots.controlButtons")
        div.modal__controls
          slot(name="controlButtons")
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Modal',
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="stylus">
.modal
  position: fixed;
  left: 0;
  top: 0;
  padding: 2rem 0;
  z-index: 10;
  height: 100%;
  overflow: auto;
  width: 100vw;
  text-align: center;
  @media (max-width: 512px)
    padding: 1rem 0;
  &:before
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  h4
    font-size 1.8rem
    font-weight 400
    text-align left
    margin-top 0
  &__bg
    background-color: rgba(0,0,0,0.6);
    position: fixed;
    top: 0; right: 0; left: 0; bottom: 0;
  &__content
    position: relative;
    z-index: 10;
    animation: contentKeyframes .2s ease-out;
    display: inline-block;
    flex-direction: column;
    width 65rem
    background: #fff;
    border-radius: .5rem;
    vertical-align: middle;
    @media (max-width: 512px)
      padding: 20px;
    &--full-width
      width calc(100% - 15rem)
  &__container
    padding 0 5rem
    font-size 2rem
  &__header
    display flex
    justify-content space-between
    align-items center
    padding-top 3rem
    padding-bottom 3rem
    border-bottom .25rem solid #cad0d5
  &__close
    cursor pointer
  &__ttl
    font-size 2.2rem
    font-weight 500
    margin 0
  &__main
    padding-top 4rem
    padding-bottom 3.5rem
  &__controls
    display flex
    align-items center
    justify-content center
@keyframes contentKeyframes {
  from {
    opacity: 0;
    transform: translateY(64px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
