import { reactive } from 'vue';
import api from '@/api';

export interface PassageOption {
  name: string,
  value: number | undefined,
  id: number,
}
export default function usePassage() {
  const passages = reactive({
    selected: {} as PassageOption,
    options: [] as PassageOption[],
    async fetch(id: number) {
      try {
        const { passages: data } = (await api.markets.findPassages(id, { page: 0, perPage: 2000 })).data.content;
        passages.options = [{ name: 'Не выбрано', id: 0, value: undefined }];
        passages.options.push(...data.map((item) => ({
          name: `${item.passageName || ''}`,
          id: item.id || 0,
          value: item.id || 0,
        })));
      } catch (e) {
        console.log(e);
      }
    },
    selectById(id: number) {
      passages.selected = passages.options?.find((item) => item.id === id) || passages.selected;
      return passages.selected;
    },
  });

  return { passages };
}
