/* eslint-disable no-param-reassign */
import {
  computed, ComputedRef, reactive, watch,
} from 'vue';
import debounce from 'lodash.debounce';
import { Pageable } from '@/types/Common';

export interface PaginationManagerProps extends Record<string, unknown>{
  filters: Record<string, unknown>
  immediateFilters: string[]
  delayedFilters: string[]
  rows: unknown[]
  fetchContents(): Promise<Pageable<unknown>>
}

export interface PaginationManager extends PaginationManagerProps {
  fetching: boolean
  pagination: {
    page: number
    pageSize: number
    totalElements: number | null
    totalPages: number | null
    lastPage: boolean
    params: {page: number; pageSize: number}
    reset(): void
  }
  fetch(nextPage?: boolean, exactlyPage?: boolean): void
  showLoader: ComputedRef<boolean>
  fetchNextPage(): Promise<void>
}

export default function usePagination(props: PaginationManagerProps): PaginationManager {
  const manager: PaginationManager = Object.assign(reactive(props), {
    fetching: false,
    pagination: {
      page: 0,
      pageSize: 20,
      totalElements: null as number | null,
      totalPages: null as number | null,
      get lastPage() {
        return this.totalPages !== null && this.page >= this.totalPages - 1;
      },
      get params() {
        return { page: this.page, pageSize: this.pageSize };
      },
      reset() {
        Object.assign(this, {
          page: 0,
          pageSize: 20,
          totalElements: null,
          totalPages: null,
        });
      },
    },
    async fetch(nextPage = false, exactlyPage = false) {
      manager.fetching = true;
      if (!nextPage || exactlyPage) manager.rows = [];
      try {
        const response = await props.fetchContents.call(this);
        const {
          page, pageSize, totalElements, totalPages, content,
        } = response;
        Object.assign(manager.pagination, {
          page, pageSize, totalElements, totalPages,
        });
        if (page === 0) { // важно для кейса, где если за время исполнения запроса поменять какой-то фильтр, то произойдет лейзилоад без очистки предыдущих записей
          manager.rows.splice(0, manager.rows.length);
        }
        manager.rows.push(...content);
      } catch (e) {
        console.log(e);
      } finally {
        manager.fetching = false;
      }
    },
    showLoader: computed(() => manager.fetching || (!manager.pagination.lastPage && manager.rows.length !== 0)),
    async fetchNextPage() {
      if (!this.showLoader || manager.fetching) return;
      manager.pagination.page += 1;
      await manager.fetch(true);
    },
  });

  const delayedFetch = debounce(() => {
    manager.rows = [];
    manager.pagination.reset();
    manager.fetch();
  }, 400);
  watch(
    () => manager.delayedFilters.map((key: string) => manager.filters[key]),
    () => {
      manager.rows = [];
      manager.fetching = true;
      delayedFetch();
    },
    { deep: true },
  );
  watch(
    () => manager.immediateFilters.map((key: string) => manager.filters[key]),
    () => {
      delayedFetch.cancel();
      manager.rows = [];
      manager.pagination.reset();
      manager.fetch();
    },
    { deep: true },
  );

  return manager;
}
