import { reactive } from 'vue';
import api from '@/api';
import { SubMarket } from '@/types/Markets';

export interface SubmarketOption extends Partial<SubMarket> {
  name: string,
  value: number | undefined,
  id: number,
}
export default function useSubMarket() {
  const subMarkets = reactive({
    selected: {} as SubmarketOption,
    options: [] as SubmarketOption[],
    async fetch(id: number) {
      try {
        const { content: data } = (await api.markets.findSubs(id, { page: 0, perPage: 2000 })).data;
        subMarkets.options = [{ name: 'Не выбрано', id: 0, value: undefined }];
        subMarkets.options.push(...data.map((item) => ({
          ...item,
          name: `${item.name || ''}`,
          id: item.id || 0,
          value: item.id || 0,
        })));
      } catch (e) {
        console.log(e);
      }
    },
    selectById(id: number) {
      subMarkets.selected = subMarkets.options?.find((item) => item.id === id) || subMarkets.selected;
      return subMarkets.selected;
    },
  });

  return { subMarkets };
}
