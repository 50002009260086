
import {
  defineComponent, reactive, watch,
} from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import api from '@/api';
import { SubMarket } from '@/types/Markets';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    data: {
      required: true,
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const currentData = reactive({});
    const modalMarketState = reactive({
      loading: false,
      async submit() {
        modalMarketState.loading = true;
        try {
          await api.markets.updateSubMarket(currentData as SubMarket);
          // alert('Успешно обновлено');
          emit('close');
        } catch (e: unknown) {
          alert((e as Record<string, unknown>)?.message || 'Неизвестная ошибка на сервере');
        } finally {
          modalMarketState.loading = false;
        }
      },
    });
    watch(() => props.data, () => {
      Object.assign(currentData, props.data);
    }, { deep: true, immediate: true });
    return {
      modalMarketState,
      currentData,
    };
  },
});
