
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import useSubMarket from '@/modules/useSubMarket';
import { useRoute, useRouter } from 'vue-router';
import { Market, MarketTaxpayer } from '@/types/Markets';
import api from '@/api';
import usePassage from '@/modules/usePassage';
import usePagination from '@/modules/usePagination';
import ModalSubMarketEdit from '@/views/markets-and-malls/components/ModalSubMarketEdit.vue';
import ModalMarketEdit from '@/views/markets-and-malls/components/ModalMarketEdit.vue';
import { Pageable } from '@/types/Common';
import ViewportObserver from '@/components/ViewportObserver.vue';
import { useStore } from 'vuex';
import router from '@/router';

interface TaxpayersFilters extends Record<string, unknown>{
  marketId: number | null,
  subMarket: number | null,
  prohod: number | null,
}

export default defineComponent({
  name: 'MarketsAndMallsMarketsMarketIdIndex',
  components: { ViewportObserver, ModalSubMarketEdit, ModalMarketEdit },
  setup() {
    const route = useRoute();
    // eslint-disable-next-line no-shadow
    const router = useRouter();
    const { subMarkets } = useSubMarket();
    const { passages } = usePassage();
    const store = useStore();

    const taxpayers = usePagination({
      rows: [] as MarketTaxpayer[],
      filters: {
        marketId: null,
        subMarket: null,
        prohod: null,
      } as TaxpayersFilters,
      immediateFilters: ['marketId', 'subMarket', 'prohod'],
      delayedFilters: [],
      async fetchContents() {
        const filters = taxpayers.filters as TaxpayersFilters;
        const marketId = filters.marketId || 0;
        if (!marketId) {
          this.rows = [];
          subMarkets.options = [];
          passages.options = [];
          // Object.assign(this.filters, { subMarket: null, prohod: null });
          return {
            content: [],
            page: 0,
            pageSize: 0,
            totalElements: 0,
            totalPages: 0,
          } as Pageable<any>;
        }
        const { subMarket, prohod } = filters;
        const duplicate = 'false';
        const params = {
          ...subMarket && { subMarket },
          ...prohod && { prohod },
          duplicate,
        };
        subMarkets.fetch(marketId);
        // eslint-disable-next-line no-unused-expressions
        subMarket && passages.fetch(subMarket);
        return (await api.markets.getTaxpayers(marketId, { ...params, ...taxpayers.pagination.params })).data;
      },
    });

    const marketInfo = reactive({
      data: null as Market | null,
      fetching: false,
      async fetch() {
        marketInfo.fetching = true;
        try {
          // eslint-disable-next-line no-use-before-define
          const id = taxpayers.filters?.marketId as number | null;
          if (!id) {
            marketInfo.data = null;
          } else {
            marketInfo.data = (await api.markets.getById(id)).data;
          }
        } catch (e: unknown) {
          console.log(e);
        } finally {
          marketInfo.fetching = false;
        }
      },
    });

    const modalMarket = reactive({
      displaySubMarketEdit: false,
      displayMarketEdit: false,
      async deleteMarket() {
        const confirmDelete = window.confirm('Вы уверены?');
        if (marketInfo?.data?.id && confirmDelete) {
          try {
            await api.markets.deleteMarket(marketInfo.data.id);
            await router.push({ name: 'markets-and-malls-markets' });
          } catch (e: unknown) {
            console.error(e);
            alert((e as Record<string, unknown>)?.message || 'Неизвестная ошибка на сервере');
          }
        }
      },
    });

    function filterFromQuery() {
      const { subMarket, prohod } = route.query as { subMarket?: string, prohod?: string };
      const { marketId } = route.params;
      const filters: Partial<TaxpayersFilters> = { marketId: +(marketId as string) || null, subMarket: +(subMarket as string) || null, prohod: +(prohod as string) || null };
      Object.assign(taxpayers.filters, filters);
    }

    function filterToQuery(filters: {marketId?: number | null, subMarket?: number | null, prohod?: number | null}) {
      // добавляет в query переданные значения
      // удаляет из query значения, которые переданы как null | void
      // const oldQuery = route.query;
      Object.keys(filters).forEach((key) => {
        delete route.query[key];
      });
      // eslint-disable-next-line no-param-reassign
      router.replace({ query: { ...route.query, ...filters } });
    }

    const subMarketInfo = computed(() => subMarkets.options.find((item) => item.id === taxpayers.filters.subMarket) || null);

    const handleCloseMarketModal = () => {
      modalMarket.displayMarketEdit = false;
      modalMarket.displaySubMarketEdit = false;
      marketInfo.fetch();
      taxpayers.fetchContents();
    };

    filterFromQuery();

    watch(
      () => [route.query],
      filterFromQuery,
      { immediate: true },
    );

    marketInfo.fetch();
    const isRoleCA = store.state.auth.isCA;

    return {
      subMarkets, filterToQuery, passages, taxpayers, marketInfo, subMarketInfo, modalMarket, handleCloseMarketModal, isRoleCA,
    };
  },
});
